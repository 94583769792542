<!-- =========================================================================================
    File Name: TheFooter.vue
    Description: Footer component
    Component Name: TheFooter
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template functional>
    <footer class="the-footer flex-wrap justify-between" :class="classes">
        <p>
        </p>
        <span class="items-center">
            <span>Powered By </span>
            <a href="https://ergmonkey.com">Ergmonkey</a>
            <a v-if="false" href="https://facebook.com/ergmonkey/" target="_blank" class="nav-link" rel="noopener"><feather-icon icon="FacebookIcon" svgClasses="h-4 w-4" style="color: #3b5998"/></a>
            <a v-if="false" href="https://facebook.com/ergmonkey/" target="_blank" class="nav-link" rel="noopener"><feather-icon icon="TwitterIcon" svgClasses="h-4 w-4" style="color: #00aced"/></a>
            <a v-if="false" href="https://facebook.com/ergmonkey/" target="_blank" class="nav-link" rel="noopener"><feather-icon icon="InstagramIcon" svgClasses="h-4 w-4" style="color: #3b5998"/></a>
        </span>
    </footer>
</template>

<script>
export default {
  name: 'the-footer',
  props: {
    classes: {
      type: String
    }
  }
}
</script>
