/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: "/dashboard",
    name: "Dashboard",
    slug: "account.home.index",
    icon: "HomeIcon",
  },
  {
    url: "/calendar",
    name: "Calendar",
    slug: "calendar",
    icon: "CalendarIcon",
  },
  {
    url: "/groups/my",
    name: "My Groups",
    slug: "my-groups",
    icon: "UsersIcon",
  },
  {
    url: "/me/2023/stats",
    name: "2023 Stats",
    slug: "2023-stats",
    icon: "GiftIcon",
    tag: 'new',
    tagColor: 'primary',
  },
  {
    url: "/reports/personal-bests",
    name: "Personal Bests",
    slug: "personal-bests",
    icon: "AwardIcon",
  },
  // {
  //   header: 'Workout Plans',
  //   icon: 'GridIcon',
  //   i18n: 'Workout Plans',
  //   tag: 'new',
  //   items: [
  //     {
  //       url: "/workout-plans",
  //       name: "Workout Plans Store",
  //       slug: "workout-plans",
  //       icon: "GlobeIcon",
  //     }, {
  //       url: "/workout-plans/my",
  //       name: "My Workout Plans",
  //       slug: "workout-plans",
  //       icon: "GridIcon",
  //     }]
  // },
  {
    header: 'Reports',
    icon: 'LayersIcon',
    i18n: 'Reports',
    items: [
      {
        url: '/reports/comparison',
        slug: 'reports-comparison',
        name: 'Comparison Report',
        icon: 'LayersIcon',
      },
      {
        url: '/reports/heart-rate',
        slug: 'reports-hr',
        name: 'Heart Rate Report',
        icon: 'HeartIcon',
      },
      {
        url: '/reports/performance',
        slug: 'reports-performance',
        name: 'Performance Report',
        icon: 'WindIcon',
      },
      {
        url: '/reports/progress',
        slug: 'reports-progress',
        name: 'Progress Report',
        icon: 'BarChartIcon',
      },
      {
        url: '/reports/stats',
        slug: 'reports-stats',
        name: 'Stats Report',
        icon: 'BarChart2Icon',
      },
      {
        url: '/reports/trend',
        slug: 'reports-trend',
        name: 'Trend Report',
        icon: 'TrendingUpIcon',
      },
      {
        url: '/reports/benchmark',
        slug: 'reports-benchmark',
        name: 'Power Report',
        icon: 'ZapIcon',
      },
      {
        url: '',
        slug: '',
        name: '',
        icon: '',
      },
      {
        url: '',
        slug: '',
        name: '',
        icon: '',
      }
    ]
  }
]
