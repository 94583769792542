<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="layout--main" :class="[layoutTypeClass, navbarClasses, footerClasses, {'no-scroll': isAppPage}]">

    <v-nav-menu
      :navMenuItems="computedNavMenuItems"
      title="ErgMonkey"
      parent=".layout--main"/>

    <div id="content-area" :class="[contentAreaClass, {'show-overlay': bodyOverlay}]">
      <div id="content-overlay"/>

      <!-- Navbar -->
      <template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">
        <the-navbar-horizontal
          :navbarType="navbarType"
          :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]"/>

        <div style="height: 62px" v-if="navbarType === 'static'"></div>

        <h-nav-menu
          :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]"
          :navMenuItems="computedNavMenuItems"/>
      </template>

      <template v-else>
        <the-navbar-vertical
          :navbarColor="navbarColor"
          :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]"/>
      </template>
      <!-- /Navbar -->

      <div class="content-wrapper">

        <div class="router-view">
          <div class="router-content">

            <transition :name="routerTransition">

              <vs-alert active="true" class="mb-6 vs-button-text text-center" v-if="showCouponCodeMessage">
                Our special Easter discount ends soon! Get 20% off any plan with code <b>easter22</b> . <u><router-link :to="{ name: 'account.billing.edit'}" title="Go to billing page" class="text-">Claim today!</router-link></u>
              </vs-alert>

              <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="router-header flex flex-wrap items-center mb-6">
                <div
                  class="content-area__heading"
                  :class="{'pr-4 border-0 md:border-r border-solid border-grey-light' : $route.meta.breadcrumb}">
                  <h2 class="mb-1">{{ routeTitle }}</h2>
                </div>

                <!-- BREADCRUMB -->
                <vx-breadcrumb class="ml-4 md:block hidden" v-if="$route.meta.breadcrumb" :route="$route" :isRTL="$vs.rtl"/>

                <portal-target name="title-bar-actions" class="ml-auto inline-flex cursor-pointer"/>

                <!-- DROPDOWN -->
                <vs-dropdown v-if="false" vs-trigger-click class="ml-auto md:block hidden cursor-pointer">
                  <vs-button radius icon="icon-settings" icon-pack="feather"/>

                  <vs-dropdown-menu class="w-32">
                    <vs-dropdown-item v-if="false">
                      <div @click="$router.push('/pages/profile').catch(() => {})" class="flex items-center">
                        <feather-icon icon="UserIcon" class="inline-block mr-2" svgClasses="w-4 h-4"/>
                        <span>Profile</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div @click="$router.push('/apps/todo').catch(() => {})" class="flex items-center">
                        <feather-icon icon="CheckSquareIcon" class="inline-block mr-2" svgClasses="w-4 h-4"/>
                        <span>Tasks</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div @click="$router.push('/apps/email').catch(() => {})" class="flex items-center">
                        <feather-icon icon="MailIcon" class="inline-block mr-2" svgClasses="w-4 h-4"/>
                        <span>Inbox</span>
                      </div>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>

                </vs-dropdown>

              </div>
            </transition>

            <div class="content-area__content">

              <back-to-top bottom="15%" :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'" visibleoffset="500" v-if="!hideScrollToTop">
                <vs-button icon-pack="feather" icon="icon-arrow-up" class="shadow-lg btn-back-to-top"/>
              </back-to-top>

              <transition :name="routerTransition" mode="out-in">
                <router-view @changeRouteTitle="changeRouteTitle" @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)"/>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <the-footer/>
    </div>
  </div>
</template>


<script>
import BackToTop from 'vue-backtotop'
import HNavMenu from '@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue'
import navMenuItems from '@/layouts/components/vertical-nav-menu/navMenuItems.js'
import guestNavMenuItems from '@/layouts/components/vertical-nav-menu/guestNavMenuItems.js'
import TheNavbarHorizontal from '@/layouts/components/navbar/TheNavbarHorizontal.vue'
import TheNavbarVertical from '@/layouts/components/navbar/TheNavbarVertical.vue'
import TheFooter from '@/layouts/components/TheFooter.vue'
import themeConfig from '@/../themeConfig.js'
import VNavMenu from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue'
import {mapGetters} from 'vuex'
import CryptoJS from 'crypto-js'
import moment from 'moment'
import Highcharts from 'highcharts'
import highContrastDark from "highcharts/themes/high-contrast-dark"
import $ from 'jquery'

export default {
  components: {
    BackToTop,
    HNavMenu,
    TheFooter,
    TheNavbarHorizontal,
    TheNavbarVertical,
    VNavMenu
  },
  data() {
    return {
      footerType: themeConfig.footerType || 'static',
      hideScrollToTop: themeConfig.hideScrollToTop,
      isNavbarDark: false,
      navbarColor: themeConfig.navbarColor || '#fff',
      navbarType: themeConfig.navbarType || 'floating',
      navMenuItems,
      routerTransition: themeConfig.routerTransition || 'none',
      routeTitle: this.$route.meta.pageTitle,
      highchartDefaults: {},
    }
  },
  watch: {
    '$route'() {
      if (this.isAuthenticated && this.authenticatedUserHasSubscriptionExpired && this.$route.name != 'account.billing.edit') {
        this.$router.push({ name: 'account.billing.edit'});
      }
      this.routeTitle = this.$route.meta.pageTitle
    },
    isThemeDark(val) {
      const color = this.navbarColor === '#fff' && val ? '#10163a' : '#fff'
      this.updateNavbarColor(color)
    },
    '$store.state.mainLayoutType'(val) {
      this.setNavMenuVisibility(val)
    },
    authenticatedUser: {
      handler(val) {
        if (val != null) {
          this.saveIntercomSettings();
          this.identifyMixpanelUser();
          this.identifyHeapUser();

          let theme = 'light';
          _.each(val.preferences, preference => {
            if (preference.preference_id == 2 && preference.value) {
              theme = 'dark';
              highContrastDark(Highcharts);
            }
          });
          this.$store.dispatch('updateTheme', theme);
        }
      },
      deep: true
    },
  },
  computed: {
    bodyOverlay() {
      return this.$store.state.bodyOverlay
    },
    contentAreaClass() {
      if (this.mainLayoutType === 'vertical') {
        if (this.verticalNavMenuWidth === 'default') return 'content-area-reduced'
        else if (this.verticalNavMenuWidth === 'reduced') return 'content-area-lg'
        else return 'content-area-full'
      } else return 'content-area-full'
    },
    footerClasses() {
      return {
        'footer-hidden': this.footerType === 'hidden',
        'footer-sticky': this.footerType === 'sticky',
        'footer-static': this.footerType === 'static'
      }
    },
    isAppPage() {
      return this.$route.meta.no_scroll
    },
    isThemeDark() {
      return this.$store.state.theme === 'dark'
    },
    layoutTypeClass() {
      return `main-${this.mainLayoutType}`
    },
    mainLayoutType() {
      return this.$store.state.mainLayoutType
    },
    navbarClasses() {
      return {
        'navbar-hidden': this.navbarType === 'hidden',
        'navbar-sticky': this.navbarType === 'sticky',
        'navbar-static': this.navbarType === 'static',
        'navbar-floating': this.navbarType === 'floating'
      }
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },
    ...mapGetters({
      authenticatedUser: 'auth/getAuthenticatedUser',
      isAuthenticated: 'auth/getIsUserAuthenticated',
      authenticatedUserHasSubscriptionExpired: 'auth/getAuthenticatedUserHasSubscriptionExpired',
    }),
    computedNavMenuItems() {
      if (this.isAuthenticated) {
        return navMenuItems;
      }
      return guestNavMenuItems;
    },
    subscriptionPlanName() {
      let subsriptionPlanName = 'Free';

      switch (this.authenticatedUser.subscription_plan_id) {
        case 1:
          subsriptionPlanName = 'Free';
          break;
        case 2:
          subsriptionPlanName = 'Performance';
          break;
        case 3:
          subsriptionPlanName = 'Premium';
          break;
        case 4:
          subsriptionPlanName = 'Performance Annual';
          break;
        case 5:
          subsriptionPlanName = 'Premium Annual';
          break;
      }

      return subsriptionPlanName;
    },
    showCouponCodeMessage() {
      return false;
      // return this.subscriptionPlanName == 'Free' || (this.authenticatedUser.next_bill_date != undefined && moment().isAfter(this.authenticatedUser.next_bill_date));
    }
  },
  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title
    },
    updateNavbarColor(val) {
      this.navbarColor = val
      if (val === '#fff') this.isNavbarDark = false
      else this.isNavbarDark = true
    },
    setNavMenuVisibility(layoutType) {
      if ((layoutType === 'horizontal' && this.windowWidth >= 1200) || (layoutType === 'vertical' && this.windowWidth < 1200)) {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
        this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')
      } else {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
      }
    },
    saveIntercomSettings() {
      if (process.env.NODE_ENV === 'production' && this.isAuthenticated) {
        const intercomSettings = {
          app_id: process.env.VUE_APP_INTERCOM_APP_ID,
          user_id: this.authenticatedUser.id,
          name: this.authenticatedUser.first_name + ' ' + this.authenticatedUser.last_name,
          email: this.authenticatedUser.email,
          payment_plan: this.subscriptionPlanName,
          next_bill_date: this.authenticatedUser.next_bill_date,
          receive_marketing_updates: this.authenticatedUser.receive_marketing_updates,
          user_hash: CryptoJS.enc.Hex.stringify(
            CryptoJS.HmacSHA256(this.authenticatedUser.id, process.env.VUE_APP_INTERCOM_SECRET_KEY)
          ),
          created_at: moment(this.authenticatedUser.created_at).format('X'),
          email_verified_at: moment(this.authenticatedUser.email_verified_at).format('X'),
        };

        window.Intercom('boot', intercomSettings);
      }
    },
    identifyMixpanelUser() {
      if (process.env.NODE_ENV === 'production' && this.isAuthenticated) {
        this.$mixpanel.identify(this.authenticatedUser.id);
        this.$mixpanel.people.set({
          "$email": this.authenticatedUser.email,
          "$name": this.authenticatedUser.first_name + ' ' + this.authenticatedUser.last_name,
          "Payment Plan": this.subscriptionPlanName,
          "Next Bill Date": this.authenticatedUser.next_bill_date,
          "$created": this.authenticatedUser.created_at,
          "Email Verified": this.authenticatedUser.email_verified_at
        });
      }
    },
    identifyHeapUser() {
      if (process.env.NODE_ENV === 'production' && this.isAuthenticated && window.heap) {
        try {
          heap.identify(this.authenticatedUser.id);
        } catch (err) {
          //
        }

        try {
          window.heap.addUserProperties({
            "email": this.authenticatedUser.email,
            "Name": this.authenticatedUser.first_name + ' ' + this.authenticatedUser.last_name,
            "payment_plan": this.subscriptionPlanName,
            "next_bill_date": this.authenticatedUser.next_bill_date,
            "created": this.authenticatedUser.created_at,
            "email_verified": this.authenticatedUser.email_verified_at
          });
        } catch (err) {
          //
        }
      }
    }
  },
  created() {
    const color = this.navbarColor === '#fff' && this.isThemeDark ? '#10163a' : this.navbarColor
    this.updateNavbarColor(color)
    this.setNavMenuVisibility(this.$store.state.mainLayoutType);
    this.saveIntercomSettings();
    this.identifyMixpanelUser();
    this.identifyHeapUser();

    if (this.authenticatedUser != null) {
      let theme = 'light';

      this.highchartDefaults = $.extend(true, {}, Highcharts.getOptions(), {});

      _.each(this.authenticatedUser.preferences, preference => {
        if (preference.preference_id == 2 && preference.value) {
          theme = 'dark';
          highContrastDark(Highcharts);
        }
      });
      this.$store.dispatch('updateTheme', theme);
    }
  }
}

</script>

