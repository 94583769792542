<template>
  <div class="the-navbar__user-meta flex items-center" v-if="authenticatedUser != null">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ authenticatedUser.first_name + ' '+ authenticatedUser.last_name }}</p>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

      <div class="con-img ml-3">
        <img :src="authenticatedUser != null ? authenticatedUser.image_url_256 : 'https://api.ergmonkey.com/img/placeholders/users_image-256.jpg'" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" v-if="false">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li>

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="$router.push('/settings').catch(() => {})">
            <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Settings</span>
          </li>

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="$router.push({ name: 'account.billing.edit'}).catch(() => {})">
            <feather-icon icon="DollarSignIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Billing</span>
          </li>

          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters({
      authenticatedUser: 'auth/getAuthenticatedUser',
    })
  },
  methods: {
    logout () {
      if (process.env.NODE_ENV === 'production') {
        window.Intercom('shutdown');
        window.heap.resetIdentity();
      }
      this.$router.push({ name: 'web.auth.logout' }).catch(() => {})
    }
  }
}
</script>
