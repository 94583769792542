/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: "/login",
    name: "Login",
    slug: "web.auth.login",
    icon: "LogInIcon",
  },
  {
    url: "https://ergmonkey.com",
    name: "Learn More",
    slug: "external",
    icon: "InfoIcon",
  },
]
